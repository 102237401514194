import { Modal } from "antd";
import React from "react";

interface IConfirmModal {
    isVisible?: boolean;
    title?: string;
    confirmCallback: () => void;
    cancelCallback: () => void;
}

function ConfirmDeleteModal(props: IConfirmModal) {
    return (
        <Modal title={props.title} open={props.isVisible} footer={null} onCancel={props.cancelCallback}>
            <div className="flex items-center gap-x-2 justify-end">
                <div onClick={props.confirmCallback} style={{ background: "red" }} className="cursor-pointer pr-2 pl-2 pt-1 pb-1 border font-medium border-solid rounded-lg bg-orange-400 text-white">
                    Xác nhận
                </div>
                <div onClick={props.cancelCallback} className="cursor-pointer pr-2 pl-2 pt-1 pb-1 border font-medium border-solid rounded-lg text-slate-400">
                    Huỷ
                </div>
            </div>
        </Modal>
    );
}

export default ConfirmDeleteModal;
