import moment from "moment";

export const renderContentWithLineBreaks = (content?: string): string => {
    if (!content) {
        return "";
    }

    const contentWithLineBreaks = content.replace(/\n/g, "<br />");

    return contentWithLineBreaks;
};

export const unixToDate = (timestamp: number) => {
    const date = moment.unix(timestamp);
    const formattedDate = date.format("HH:mm DD/MM/YYYY");

    return formattedDate;
};

export const formatValueReadings = (value: number) => {
    if (value >= 1e9) {
        return (value / 1e9).toFixed(1) + "B";
    } else if (value >= 1e6) {
        return (value / 1e6).toFixed(1) + "M";
    } else if (value >= 1e3) {
        return (value / 1e3).toFixed(1) + "K";
    } else {
        return value.toString();
    }
};

export const formatteDay = (timestamp: number) => {
    const date = moment.unix(timestamp);
    const formattedDate = date.format("YYYY-MM-DD ");

    return formattedDate;
};

export const formatStringSlug = (value: string) => {
    let formatted = value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    formatted = formatted.replace(/\s+/g, "-");
    formatted = formatted.replace(/[^a-z0-9-]/g, "");
    return formatted;
};
export const displayNumber = (number: number) => {
    const roundedNum = parseFloat(number.toFixed(1));
    return roundedNum % 1 === 0 ? roundedNum.toFixed(1) : roundedNum.toString();
};
