import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["toast","success","error"] */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick-theme.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/main-banner.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/hooks/useAuth.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/hooks/useDebounce.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/hooks/useImageOnLoad.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/hooks/useMediaQuery.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/hooks/useMessage.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/hooks/useScroll.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/hooks/useSsr.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layouts/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layouts/policy-footer/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/modules/rate-hight/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/modules/section-ranking/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/modules/section-update/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/modules/slider/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/modules/top-nomonation/index.tsx");
